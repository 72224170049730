<template>
    <div>
		
		<div class="modal fade  modalstyle2 unset-height" id="moreInfoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-top">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <feather-icon icon="XIcon" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <div class="bold text-purp mb-3"> {{$t('gen.permit_p')}}</div>
                    </div>
                    <div class="light">
						{{$store.state.home.appInfo.allowed_collect_txt}}
                    </div>
                    <div class=" mt-1">
                        {{$t('gen.permit_p2')}} <span>({{$store.state.home.appInfo.collect_fees_per_day}})</span> {{$t('gen.permit_p1')}}
                    </div>
                    <a href="" class="btn btn-success "> {{$t('gen.pay1')}}</a>
                </div>
            </div>
        </div>
    </div>
        <!-- login modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade logmo-style" id="loginmo" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="loginModal"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<div> {{$t('gen.signup1')}}</div>
										<a class="btn btn-light" data-dismiss="modal" data-toggle="modal"
											href="#regmodal"> {{$t('gen.signup14')}}</a>
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title"> {{$t('gen.signup13')}}</div>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close" id="login-close">
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<div class="bold">{{$t('gen.signup38')}}</div>
										<div class="light"> {{$t('gen.signup5')}}</div>
									</div>
									<form method="post" onsubmit="event.preventDefault();">
										<div class="row">
											<div class="col-md-12" v-if="0">
												<button type="button" @click="AAuthProvider('google')" class="btn soc-btn google">
													<span class="i-wr">
														<i class="fab fa-google"></i>
													</span>
													<span> {{$t('gen.signup15')}}</span>
												</button>
											</div>
											<!-- <div class="col-md-6">
												<button type="button" @click="AAuthProvider('facebook')" class="btn soc-btn face">
													<span class="i-wr">
														<i class="fab fa-facebook-f"></i>
													</span>
													<span>{{ $t('gen.signup16') }}</span>
												</button>
											</div> -->
											<div class="hr-wr col-12" v-if="0">
												<hr>
												<span class="hr-text">
													{{$t('gen.signup17')}}
												</span>
											</div>
											<div class="hr-wr col-12" v-if="(Object.keys($store.state.auth.loginerrors).length > 0)">
														<div class="alert alert-danger" role="alert">
															<ul>
																<li v-for="item in Object.keys($store.state.auth.loginerrors).map((index)=>{return $store.state.auth.loginerrors[index][0]})" :key="item">
																	{{ item }}
																</li>
															</ul>
														</div>
												</div>
											<div class="hr-wr col-12" v-if="login_msg != ''">
														<div class="alert alert-danger" role="alert">
															<ul>
																<li>
																	{{ login_msg }}
																</li>
															</ul>
														</div>
												</div>
											<div class="form-group col-12">
														<div class="icon-inp-wr2" :class="(!phone_validate ? 'wrong-phone' : '') + ' ' + (($store.state.auth.errors.name !== undefined )? 'has-error' : '')">
															<span> <!-- <feather-icon icon="PhoneIcon" /> --></span>
															<vue-tel-input class="form-control phone-in" :disabledFormatting="true" v-model="logphone" @onInput="onInputL"  v-bind="bindPropsL"></vue-tel-input>
														</div>
														
															<span style="color:red;" v-if="$store.state.auth.registererrors.phone">
																{{ $store.state.auth.registererrors.phone[0] }}
																</span>
															<span style="color:#e36f0d;" v-if="!login_phone_validate">
																{{$t('gen.signup20')}}
																</span>
														<!-- </div> -->
											</div>
											<div class="col-12 text-center log-bottom">
												<!-- <a type="button" class="text-primary" data-dismiss="modal" data-toggle="modal"
													data-target="#forgetmo">نسيت كلمة المرور ؟</a> -->
												<div>
													<!-- <button type="button" @click="signin" class="btn btn-primary">تسجيل الدخول</button> -->
													<button type="button" :disabled="!login_phone_validate || login_spinner"  v-if="timer <= 0" @click="signinSendOtp" class="btn btn-primary">{{ $t('gen.signup22') }}  </button>
													<button type="button" :disabled="timer > 0"  v-if="timer > 0" @click="signinSendOtp" class="btn btn-primary">{{ $t('gen.signup22') }}  {{timer}}</button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="login_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a href="#" data-dismiss="modal" data-toggle="modal" data-target="#regmodal"> {{$t('gen.signup2')}}</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- login modal end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->

		<!-- login modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade logmo-style" id="regmodal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="registerModal">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<div> {{$t('gen.signup3')}}</div>
										<a class="btn btn-light" data-dismiss="modal" data-toggle="modal"
											href="#loginmo"> {{$t('gen.signup13')}}</a>
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title">{{$t('gen.signup14')}}</div>
										<button type="button" class="close" id="register-close" data-dismiss="modal" aria-label="Close" >
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<div class="bold"> {{$t('gen.signup38')}}</div>
										<div class="light"> {{$t('gen.signup23')}}</div>
									</div>
									<form method="post" onsubmit="event.preventDefault();">
										<div class="row">
											<div class="col-md-12" v-if="0">
												<button type="button" @click="AAuthProvider('google')" class="btn soc-btn google">
													<span class="i-wr">
														<i class="fab fa-google"></i>
													</span>
													<span>{{$t('gen.signup15')}}</span>
												</button>
											</div>
											<!-- <div class="col-md-6">
												<button type="button" @click="AAuthProvider('facebook')" class="btn soc-btn face">
													<span class="i-wr">
														<i class="fab fa-facebook-f"></i>
													</span>
													<span>{{ $t('gen.signup16') }}</span>
												</button>
											</div> -->
											<div class="hr-wr col-12" v-if="0">
												<hr>
												<span class="hr-text">
													{{$t('gen.signup17')}}
												</span>
											</div>
													<div v-if="(Object.keys($store.state.auth.errors).length > 0)" class="hr-wr col-12">
														<div class="alert alert-danger" role="alert">
															<ul>
																<li v-for="item in Object.keys($store.state.auth.errors).map((index)=>{return $store.state.auth.errors[index][0]})" :key="item">
																	{{ item }}
																</li>
															</ul>
														</div>
													</div>
														
											<div class="form-group col-md-6">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="UserIcon" /></span>
													<input type="text" class="form-control" v-model="name"   :class="($store.state.auth.errors.name !== undefined )? 'has-error' : ''"
													:placeholder="$t('gen.signup18')">
													</div>
													<span style="color:red;" v-if="$store.state.auth.registererrors.name">
													{{ $store.state.auth.registererrors.name[0] }}
													</span>
											</div>
											<div class="form-group col-md-6" v-if="0">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="MailIcon" /></span>
													<input type="email" class="form-control" v-model="email"   :class="($store.state.auth.errors.email !== undefined )? 'has-error' : ''"
														:placeholder="$t('gen.signup19')">
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.email">
													{{ $store.state.auth.registererrors.email[0] }}
													</span>
											</div>
											<div class="form-group col-md-6">
												<div class="icon-inp-wr2" :class="(!phone_validate ? 'wrong-phone' : '') + ' ' + (($store.state.auth.errors.name !== undefined )? 'has-error' : '')">
													<span> <!-- <feather-icon icon="PhoneIcon" /> --></span>
													<vue-tel-input class="form-control phone-in" :disabledFormatting="true" v-model="phone" @onInput="onInputR"  v-bind="bindPropsR"></vue-tel-input>
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.phone">
													{{ $store.state.auth.registererrors.phone[0] }}
													</span>
												<span style="color:#e36f0d;" v-if="!reg_phone_validate">
													{{$t('gen.signup20')}}
													</span>
											</div>
											<div class="form-group col-md-6" v-if="0">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="UserIcon" /></span>
													<input type="text" class="form-control" v-model="username"   :class="($store.state.auth.errors.username !== undefined )? 'has-error' : ''"
														placeholder="اسم المستخدم"  v-on:keypress="isLetter($event)">
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.username">
													{{ $store.state.auth.registererrors.username[0] }}
													</span>
											</div>
											<!-- <div class="form-group col-md-6">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="LockIcon" /></span>
													<input :type="password_2" class="form-control pass-inp" v-model="password"    :class="($store.state.auth.errors.password !== undefined )? 'has-error' : ''"
														placeholder="ادخل كلمة المرور">
													<button type="button" @click="togglepassword2" class="btn show-pass">
														<i v-if="password_2 == 'password'" class="far fa-eye"></i>
														<i v-if="password_2 == 'text'" class="far fa-eye-slash"></i>	
													</button>
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.password">
													{{ $store.state.auth.registererrors.password[0] }}
													</span>
											</div> -->
											<!-- <div class="form-group col-md-6">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="LockIcon" /></span>
													<input :type="password_3" class="form-control pass-inp" v-model="password_confirmation"   :class="($store.state.auth.errors.password_confirmation !== undefined )? 'has-error' : ''"
														:placeholder="$t('gen.placeholder_confirm_pass')">
													<button type="button" @click="togglepassword3" class="btn show-pass">
														<i v-if="password_3 == 'password'" class="far fa-eye"></i>
														<i v-if="password_3 == 'text'" class="far fa-eye-slash"></i>
													</button>
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.password_confirmation">
													{{ $store.state.auth.registererrors.password_confirmation[0] }}
													</span>
											</div> -->
											<div class="custom-control custom-control-success custom-checkbox">
												<input type="checkbox" class="custom-control-input " id="Check32" v-model="agree">
												<label class="custom-control-label" for="Check32" style="color:#000;">
													{{$t('gen.signup6')}} <router-link to="/privacy-policy" target="_blank"> {{$t('gen.signup7')}}</router-link> {{$t('gen.and')}} <router-link to="/terms-and-conditions" target="_blank"> {{$t('gen.signup8')}}</router-link>
												</label>
											</div>
											<p v-if="show_agree" style="color:red;">{{agree_txt}}</p>
											<div class="col-12 text-center log-bottom">
												<div>
													<button :disabled="!reg_phone_validate || register_spinner" type="button" @click="register" class="btn btn-primary">{{$t('gen.signup9')}} </button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="register_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a data-dismiss="modal" data-toggle="modal" href="#loginmo"> {{$t('gen.signup4')}}</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- login modal end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->
	
		<!-- forget modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade logmo-style addClassToBody" id="forgetmo" tabindex="-1" role="dialog" data-backdrop="static"
			aria-labelledby="forgetModal" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<a class="btn btn-light" data-dismiss="modal" data-toggle="modal" href="#loginmo">
											{{$t('gen.signup13')}}</a>
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title"> {{$t('gen.signup11')}}</div>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<div class="bold"> {{$t('gen.signup21')}}</div>
										<div class="light"> {{$t('gen.signup10')}}</div>
									</div>
									<form id="forget-form" @submit.prevent="ForgetPass">
										
										<div class="row">
											<div class="form-group col-12">
												<div class="icon-inp-wr2" :class="(!phone_validate ? 'wrong-phone' : '') + ' ' + (($store.state.auth.errors.name !== undefined )? 'has-error' : '')">
														<span> <!-- <feather-icon icon="PhoneIcon" /> --></span>
															<vue-tel-input class="form-control phone-in" :disabledFormatting="true" name="email" v-model="forgphone" @onInput="onInput"  v-bind="bindProps"></vue-tel-input>
												</div>
												<span style="color:red;" v-if="$store.state.auth.registererrors.phone">
													{{ $store.state.auth.registererrors.phone[0] }}
												</span>
												<span style="color:#e36f0d;" v-if="!phone_validate">
													{{$t('gen.signup20')}}
												</span>
											</div>
											<!-- <div class="form-group col-12">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="MailIcon" /></span>
													<input type="email" class="form-control"   name="email"
														placeholder="ادخل بريدك الالكتروني">
												</div>
												<span v-if="forget_pass != ''" style="color:red;">{{forget_pass}}</span>
											</div> -->
											<div class="col-12 text-center log-bottom">
												<div>
													<button type="submit" class="btn btn-primary">{{$t('gen.signup37')}}</button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="g_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
													<button data-dismiss="modal" data-toggle="modal"
														data-target="#vercodemo" id="open-verify-2" style="display:none;" class="btn btn-primary">{{$t('gen.signup37')}}</button>
													<button data-dismiss="modal" data-toggle="modal" type="button"
														data-target="#vercodemo2" id="open-verify-3" style="display:none;" class="btn btn-primary">{{$t('gen.signup37')}}</button>
												</div>
											</div>
										</div>
									</form>
								<button data-dismiss="modal" data-toggle="modal" data-target="#loginmo"
									class="btn back-btn">
									{{ $t('gen.signup30') }}
									<feather-icon icon="ChevronsLeftIcon" />
								</button>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a href="" data-dismiss="modal" data-toggle="modal" data-target="#loginmo">{{$t('gen.signup13')}}
									</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!--  forget end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->


		<!-- ver code modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade logmo-style setheigt addClassToBody" data-backdrop="static" data-keyboard="false" id="vercodemo" ref="verifyPass" tabindex="-1" role="dialog"
			aria-labelledby="2" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<!-- <a class="btn btn-light" data-dismiss="modal" data-toggle="modal" href="#loginmo">
											تسجيل الدخول</a> -->
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title">{{$t('gen.signup11')}}</div>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<!-- <div class="bold">تم ارسال الرابط لبريدك الالكتروني بنجاح</div> -->
										<div class="light">{{ $t('gen.enter_otp_end_to_email') }}
										</div>
										<div class="light text-purp">
											{{forget_email}}
										</div>
									</div>
									<form  @submit.prevent="vForgetPass">
										<div class="row">
											<div class="form-group col-12">
												<div class="otp-wr">
                                                   <input type="number" id="otpe1" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otpe2" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otpe3" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otpe4" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                    <input type="number" id="otpe5" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                     <input type="number" id="otpe6" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                 </div>
												 <div class="error1 text-center" v-if="verify_pass != ''" style="color:red;">{{verify_pass}}</div>
												<!-- direction ltr -->
												<!-- <input type="number" class="form-control" v-model="num1" placeholder="ادخل  رمز التحقق"> -->
												<!-- <div class="ver-inp-wr">
													<input type="number" name="" class="form-control">
												</div> -->
											</div>
												<!-- <span v-if="verify_pass != ''" style="color:red;">{{verify_pass}}</span> -->
											<div class="col-12 text-center log-bottom">
												<div>
													<button type="submit" class="btn btn-primary">{{$t('gen.signup27')}}</button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="g_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
													<button data-dismiss="modal" data-toggle="modal" id="open-reset-2" style="display:none;"
														data-target="#resetpass" class="btn btn-primary">{{$t('gen.signup27')}}</button>
												</div>
												<a @click="ReForgetPass" class="resend"> {{$t('gen.signup29')}}</a>
											</div>
										</div>
									</form>
									<button data-dismiss="modal" data-toggle="modal" data-target="#forgetmo"
										class="btn back-btn">
										{{$t('gen.signup30')}}
										<feather-icon icon="ChevronsLeftIcon" />
									</button>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a href="" data-dismiss="modal" data-toggle="modal" data-target="#loginmo">{{$t('gen.signup13')}}
									</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="modal fade logmo-style setheigt addClassToBody" data-backdrop="static" data-keyboard="false" id="vercodemo2" ref="verifyPass2" tabindex="-1" role="dialog"
			aria-labelledby="verficationModal2" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<a class="btn btn-light" data-dismiss="modal" data-toggle="modal" href="#loginmo">
											{{$t('gen.signup13')}}</a>
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title"> {{$t('gen.signup26')}}</div>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<div class="bold"> {{$t('gen.signup24')}}</div>
										<div class="light"> {{$t('gen.signup25')}}</div>
										<!-- <div class="bold">تم ارسال كود التفعيل لبريدك الالكتروني بنجاح</div>
										<div class="light">ادخل رمز التحقق المرسل لبريدك الالكتروني
											المسجل
										</div> -->
										<div class="light text-purp">
											{{forget_email}}
										</div>
									</div>
									<form  @submit.prevent="vNewAcc">
										<div class="row">
											<div class="form-group col-12">
												<!-- direction ltr -->
												<div class="otp-new-s" style="display:none;">
															<input type="text" autocomplete="one-time-code" inputmode="numeric" maxlength="6" pattern="\d{6}">

														</div>
												<div class="otp-wr">
                                                   <input type="number" id="otp1" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otp2" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otp3" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                   <input type="number" id="otp4" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                    <input type="number" id="otp5" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                     <input type="number" id="otp6" autocomplete="true" class="form-control otp-input" maxlength="1">
                                                 </div>
												 <div class="error1 text-center" v-if="verify_pass != ''" style="color:red;">{{verify_pass}}</div>
                                            <!-- <div class="error1 text-center" v-if="opt_error_phone != ''">{{opt_error_phone}} </div> -->
												<!-- <input type="text" maxlength="4" class="form-control" v-model="num1" placeholder="ادخل  رمز التحقق"> -->
												<!-- <div class="ver-inp-wr">
													<input type="number" name="" class="form-control">
												</div> -->
											</div>
											<div class="col-12 text-center log-bottom">
												<div>
													<button type="submit" class="btn btn-primary">{{$t('gen.signup27')}}</button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="g_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
												</div>
												
												<div class="waitMsg" v-if="timer > 0">
                                                {{$t('gen.signup31')}}
                                                <span class="mycounter" >{{timer}}</span>
                                                {{$t('gen.signup28')}}
                                            </div>
												<a @click="ReSendOtp" class="resend" v-if="timer == 0" style="display:block; cursor: pointer;">{{$t('gen.signup29')}}</a>
											</div>
										</div>
									</form>
									<button v-if="login_otp_send" data-dismiss="modal" data-toggle="modal" data-target="#loginmo"
										class="btn back-btn">
										{{$t('gen.signup30')}}
										<feather-icon icon="ChevronsLeftIcon" />
									</button>
									<button v-if="!login_otp_send" data-dismiss="modal" data-toggle="modal" data-target="#regmodal"
										class="btn back-btn">
										{{$t('gen.signup30')}}
										<feather-icon icon="ChevronsLeftIcon" />
									</button>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a href="" data-dismiss="modal" data-toggle="modal" data-target="#loginmo">{{$t('gen.signup13')}}
									</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!--  forget end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->
		<!-- reset modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade logmo-style setheigt addClassToBody" id="resetpass" tabindex="-1" role="dialog" data-backdrop="static"
			aria-labelledby="resetpass" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-body login-style">
						<div class="container-fluid">
							<div class="row">
								<!-- hide in sm-screens start -->
								<div class="col-lg-4 bg-col d-none d-lg-flex">
									<div class="wr">
										<div class="img-wr">
											<img src="../../../@core/pic/vlogo.png" alt="">
										</div>
										<a class="btn btn-light" data-dismiss="modal" data-toggle="modal" href="#loginmo">
											{{$t('gen.signup13')}}</a>
									</div>
								</div>
								<!-- hide in small screens end -->
								<div class="col-lg-8 cont-col">
									<div class="modal-top">
										<div class="modal-title">{{$t('gen.signup11')}}</div>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true"><feather-icon icon="XIcon" /></span>
										</button>
									</div>
									<div class="text-center">
										<div class="bold">{{$t('gen.signup11')}}</div>
										<div class="light"> {{$t('gen.signup12')}} </div>
									</div>
									<form id="reset-form" @submit.prevent="resetPass">
										<div class="row">
											<div class="form-group col-md-12">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="LockIcon" /></span>
													<input :type="password_1_1 ? 'password':'text'" class="form-control pass-inp" name="password"
														placeholder="ادخل كلمة المرور">
													<button type="button" @click="password_1_1 = !password_1_1" class="btn show-pass">
														<i v-if="password_1_1" class="far fa-eye"></i>
														<i v-if="!password_1_1" class="far fa-eye-slash"></i>
													</button>
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="icon-inp-wr">
													<span> <feather-icon icon="LockIcon" /></span>
													<input :type="password_1_2 ? 'password':'text'" class="form-control pass-inp" name="password_confirmation"
														:placeholder="$t('gen.placeholder_confirm_pass')">
													<button type="button" @click="password_1_2 = !password_1_2" class="btn show-pass">
														<i v-if="password_1_2" class="far fa-eye"></i>
														<i v-if="!password_1_2" class="far fa-eye-slash"></i>
													</button>
												</div>
											</div>
												<span v-if="change_pass != ''" style="color:red;">{{change_pass}}</span>
											<div class="col-12 text-center log-bottom">
												<div>
													<button type="submit" class="btn btn-primary">{{$t('gen.signup32')}}</button>
													<div style="margin-top: 1.5rem;">
														<div class="spinner-border text-info loading-spinner" v-if="g_spinner" role="status">
														<span class="sr-only">Loading...</span>
														</div>

													</div>
													<button type="button" style="display:none;" id="open-success-2" class="btn btn-primary" data-dismiss="modal" 
													data-toggle="modal" data-target="#donemodal">{{$t('gen.signup32')}}</button>
												</div>
											</div>
										</div>
									</form>
								</div>
								<!-- show in sm screen only start -->
								<div class="col-12 bottom-bar d-flex d-lg-none">
									<a href="" data-dismiss="modal" data-toggle="modal" data-target="#loginmo"> {{$t('gen.signup13')}}
									</a>
								</div>
								<!-- show in sm screen only start -->
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!--  reset end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->
		<!-- done modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade addClassToBody modalstyle2" id="donemodal" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog  modal-dialog-centered ">
				<div class="modal-content">
					<div class="modal-top">
						<button type="button" id="close-suc" class="close" data-dismiss="modal" aria-label="Close"  @click="redir">
							<feather-icon
										icon="XIcon"
										/>
						</button>
					</div>
					<div class="modal-body">
						<div class="img-wr">
							<img src="../../../@core/pic/checked (1).png" alt="">
						</div>
						<div class="bold"> {{$t('gen.signup33')}}</div>
						<!-- <div class="light">هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص</div> -->
						<div class="col-12 text-center log-bottom">
							<button class="btn btn-primary"  @click="redir">{{$t('gen.signup34')}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  done end  eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee -->
		<!-- error modal start  sssssssssssssssssssssssssssssssssssssssssssss -->
		<div class="modal fade addClassToBody modalstyle2" id="errormodal" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog  modal-dialog-centered ">
				<div class="modal-content">
					<div class="modal-top">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="redir2">
							<feather-icon
										icon="XIcon"
										/>
						</button>
					</div>
					<div class="modal-body">
						<div class="img-wr">
							<img src="../../../@core/pic/exclamation-mark (1).png" alt="">
						</div>
						<div class="bold"> {{$t('gen.signup35')}}</div>
						<!-- <div class="light">هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص -->
					</div>
					<div class="col-12 text-center log-bottom">
						<button class="btn btn-primary" @click="redir2">{{$t('gen.signup34')}}</button>
					</div>
				</div>
			</div>
		</div>
		<div class="page-overlay" :class="($store.state.auth.loadingPage == 1) ? 'show' : ' display-none'">
            <div class="cont">
                <div class="empty-wr">
                    <div class="img-wr">
                        <img src="../../../@core/pic/empty.png" alt="">
                        <h5> {{$t('gen.signup36')}}</h5>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script> import 'bootstrap'
import store from '@/store'
  import axios from "../../../axios";
import { mapGetters, mapActions ,mapMutations} from "vuex";
export default {
	data() {
		return {
			login_otp_send: false,
			verify_type: 'register',
			login_msg: '',
			t_email: '',
			timer:-1,
			password_1_2: 1,
			password_1_1: 1,
			data:{
				email: '',
				password: ''
			},
			agree: false,
			show_agree: false,
			agree_txt: this.$t('gen.check_aggree_txt'),
			logemail: '',
			logphone: '',
			logpass: '',
			name: '',
			num1: '',
			num4: '',
			num2: '',
			num3: '',
			num5: '',
			num6: '',
			username: '',
			email: '',
			phone: '',
			phone_num: '',
			password: '',
			password_1: 'password',
			password_2: 'password',
			password_3: 'password',
			password_confirmation: '',
			login_spinner: false,
			g_spinner: false,
			register_spinner: false,
			bindProps: {
				mode: "national",
				defaultCountry: "SA",
				disabledFetchingCountry: false,
				disabled: false,
				placeholder: this.$t('gen.enter_your_whatsapp_number'),
				required: false,
				enabledCountryCode: false,
				enabledFlags: true,
				preferredCountries: ["SA", "EG"],
				onlyCountries: [],
				ignoredCountries: [],
				autocomplete: "off",
				name: "telephone",
				maxLen: 25,
				wrapperClasses: "",
				inputClasses: "",
				dropdownOptions: {
				disabledDialCode: false
				},
				inputOptions: {
					showDialCode: false
				}
			},
			bindPropsR: {
				mode: "national",
				defaultCountry: "SA",
				disabledFetchingCountry: false,
				disabled: false,
				placeholder: this.$t('gen.enter_your_whatsapp_number'),
				required: true,
				enabledCountryCode: true,
				enabledFlags: true,
				preferredCountries: ["SA", "EG"],
				onlyCountries: [],
				ignoredCountries: [],
				autocomplete: "true",
				name: "",
				maxLen: 25,
				wrapperClasses: "",
				inputClasses: "",
				dropdownOptions: {
				disabledDialCode: false
				},
				inputOptions: {
					showDialCode: false
				}
			},
			bindPropsL: {
				mode: "national",
				defaultCountry: "SA",
				disabledFetchingCountry: false,
				disabled: false,
				placeholder: this.$t('gen.enter_your_whatsapp_number'),
				required: true,
				enabledCountryCode: true,
				enabledFlags: true,
				preferredCountries: ["SA", "EG"],
				onlyCountries: [],
				ignoredCountries: [],
				autocomplete: "true",
				name: "",
				maxLen: 25,
				wrapperClasses: "",
				inputClasses: "",
				dropdownOptions: {
				disabledDialCode: false
				},
				inputOptions: {
					showDialCode: false
				}
			},
			phone_validate: true,
			reg_phone_validate: false,
			login_phone_validate: false,
			country_data: '',
			l_country_data: '',
			r_country_data: '',
			forget_email: null,
			forget_pass: '',
			verify_pass: '',
			change_pass: '',
			forgphone: '',
            intervalT: {}
		}
	},
	watch: {
		timer(v){
			if(v == 0){
				clearInterval(this.intervalT);
			}
		},
		name(val){
			delete this.$store.state.auth.registererrors.name;
		},
		phone(val){
			delete this.$store.state.auth.registererrors.phone;
		},
		email(val){
			delete this.$store.state.auth.registererrors.email;
		},
		username(val){
			delete this.$store.state.auth.registererrors.username;
		},
		password(val){
			delete this.$store.state.auth.registererrors.password;
		},
		agree(val){
			this.show_agree == false;
		},
	},
	methods: {
    	...mapActions("auth", ["login", 'signup']),
		signin()
		{
			this.login_spinner = true;
			// console.log('data: ', this.country_data);
			// this.data.email = this.logemail;
			this.data.phone = this.phone_num;
			this.data.country_data = this.l_country_data;
			// this.data.password = this.logpass;
			this.data.otp = document.getElementById('otpl1').value+document.getElementById('otpl2').value+document.getElementById('otpl3').value+document.getElementById('otpl4').value+document.getElementById('otpl5').value+document.getElementById('otpl6').value;
			this.login(this.data).then(response => {
            	// console.log("Got some data, now lets show something in this component")
				this.login_spinner = false;
				if (response.data.code == 103) {
					this.forget_email = this.phone_num;
					document.getElementById('login-close').click();
					document.getElementById('open-verify-3').click();
					document.getElementById('otp1').focus();

				}
			}, error => {
				this.login_spinner = false;
				// console.error("Got nothing from server. Prompt user to check internet connection and try again")
			});

		},
		signinSendOtp()
		{
			this.login_spinner = true;
			let phone = this.phone_num;
			let country_data = this.l_country_data;
			
			this.t_email = phone;
            axios.post(`/send-otp`, {email: phone, country_data: country_data}).then((response) => {
				this.login_spinner = false;
				if(response.data.code == 200){
					this.verify_type = 'login';
					this.forget_email = this.phone_num;
					this.login_otp_send = true;

					document.getElementById('login-close').click();
					document.getElementById('open-verify-3').click();
					setTimeout(() => {
						document.getElementById('otp1').focus();						
					}, 500);
					this.timer = 60;
					clearInterval(this.intervalT);
					this.intervalT = setInterval(() => {
						this.timer--;
					}, 1000);
					
				}else{
					this.login_otp_send = false;
					this.login_msg = response.data.message;
				}
              }).catch((error) => {
				this.login_spinner = false;
				this.login_otp_send = false;
            });

		},
		register()
		{
			this.login_otp_send = false;
			// if(this.reg_phone_validate == false){
			// 	console.log("this.reg_phone_validate", this.reg_phone_validate);
			// }else{
			// 	console.log("this.reg_phone_validate2", this.reg_phone_validate);
			// }
			if(this.agree == false)
			{
				this.show_agree = true;
				return;
			}else{
				this.show_agree = false;

			}
			this.register_spinner = true;
			this.data.name = this.name;
			// this.data.username = this.username;
			this.data.phone = this.phone_num;
			this.data.is_valid_phone = this.phone_validate;
			this.data.country_data = this.r_country_data;
			// this.data.email = this.email;
			// this.data.password = this.password;
			// this.data.password_confirmation = this.password_confirmation;
			this.data.agree = this.agree;
			this.signup(this.data).then(response => {
            	// console.log("Got some data, now lets show something in this component")
				this.register_spinner = false;
				if(response.data.code == 200){
					this.verify_type = 'register';

					this.forget_email = this.phone_num;

					document.getElementById('register-close').click();
					document.getElementById('open-verify-3').click();
					setTimeout(() => {
						document.getElementById('otp1').focus();						
					}, 500);
					// this.sendOtp(this.email);
					this.sendOtp(this.phone);
					window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'event': 'SignedUp',
							'customer_name': this.data.name,
							'customer_email': '',
							'customer_phone': this.data.phone,
							'customer_id': response.data.data.user.id,

						});
					// this.name = '';
					// this.username = '';
					// this.phone = '';
					// this.country_data = '';
					// this.email = '';
					// this.password = '';
					// this.password_confirmation = '';
				}else{

				this.$store.state.auth.registererrors = response.data.errors;
				}
			}, error => {
				this.register_spinner = false;
				// console.error("Got nothing from server. Prompt user to check internet connection and try again")
			});
		},
		togglepassword1(){
			if (this.password_1 == 'password') {
				this.password_1 = 'text';
			} else {
				this.password_1 = 'password';
			}
		},
		togglepassword2(){
			if (this.password_2 == 'password') {
				this.password_2 = 'text';
			} else {
				this.password_2 = 'password';
			}
		},
		togglepassword3(){
			if (this.password_3 == 'password') {
				this.password_3 = 'text';
			} else {
				this.password_3 = 'password';
			}
		},
		onInput({ number, isValid, country }) {
			this.phone_validate = isValid;
			this.country_data = country;
			// console.log("country",country.iso2);
			this.phone_num = number.significant;
		},
		onInputR({ number, isValid, country }) {
			this.reg_phone_validate = isValid;
			this.r_country_data = country;
			// console.log("country",country.iso2, isValid);
			this.phone_num = number.significant;
		},
		onInputL({ number, isValid, country }) {
			this.login_phone_validate = isValid;
			this.l_country_data = country;
			// console.log("country",country.iso2, isValid);
			this.phone_num = number.significant;
		},
		AAuthProvider(provider) {

			// document.getElementById('register-close').click();
			// document.getElementById('login-close').click();
			var self = this
			this.$auth.authenticate(provider).then(response =>{
			self.SocialLogin(provider,response)
			/*this.sociallogin(provider,response).then(response => {
            	// console.log("Got some data, now lets show something in this component")
				this.register_spinner = false;
			}, error => {
				this.register_spinner = false;
				// console.error("Got nothing from server. Prompt user to check internet connection and try again")
			});*/

			}).catch(err => {
				console.log({err:err})
			})

		},

		SocialLogin(provider,response){

			this.$http.post('/providerlogin/'+provider,response).then(response => {

				window.localStorage.setItem('authToken', response.data.data.user.token)
				
				window.localStorage.setItem('authName', response.data.data.user.name)
				location.replace('/home')

			}).catch(err => {
				console.log({err:err})
			})
		},
		ForgetPass(){
			this.g_spinner = true;
            const form = document.getElementById('forget-form');
            const formData = new FormData(form);
			this.$http.post('/forgetpassword',formData).then(response => {

				if(response.data.code == 200)
				{
					this.forget_pass = '';
					this.forget_email = response.data.data.email;
					this.g_spinner = false;
					document.getElementById('open-verify-2').click();
					document.getElementById('otpe1').focus();
					
				}else{
					this.g_spinner = false;
					this.forget_pass = response.data.message;
				}
				// this.$refs.verifyPass.classList.toggle('show');
				// this.$refs.verifyPass.classList.toggle('d-block');
			}).catch(err => {
				console.log({err:err})
			})
		},
		ReForgetPass(){
			this.g_spinner = true;
            const form = document.getElementById('forget-form');
            const formData = new FormData(form);
			this.$http.post('/forgetpassword',formData).then(response => {

				if(response.data.code == 200)
				{
					this.forget_pass = '';
					this.forget_email = response.data.data.phone;
					this.g_spinner = false;
					
				}else{
					this.g_spinner = false;
					this.forget_pass = response.data.message;
				}
				// this.$refs.verifyPass.classList.toggle('show');
				// this.$refs.verifyPass.classList.toggle('d-block');
			}).catch(err => {
					this.g_spinner = false;
				console.log({err:err})
			})
		},
		isLetter(e) {
			let char = String.fromCharCode(e.keyCode); // Get the character
			if(/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
			else e.preventDefault(); // If not match, don't add to input text
		},
		vForgetPass(){
			
			this.g_spinner = true;
			let data = {
				otp: document.getElementById('otpe1').value+document.getElementById('otpe2').value+document.getElementById('otpe3').value+document.getElementById('otpe4').value+document.getElementById('otpe5').value+document.getElementById('otpe6').value,
				email: this.forget_email
			}
			this.$http.post('/verifyforgetpassword',data).then(response => {

				if(response.data.code == 200)
				{
					this.verify_pass = '';
					window.localStorage.setItem('authName', response.data.data.user.name)
					window.localStorage.setItem('auth', response.data.data.user.id)
					window.localStorage.setItem('authToken', response.data.data.user.token)
					document.getElementById('open-reset-2').click();
					this.$store.state.auth.commit("setAuthToken", { token: response.data.data.user.token })
				}else{
					this.verify_pass = response.data.message;
				}
				
			this.g_spinner = false;
			// this.$refs.verifyPass.classList.toggle('show');
			// this.$refs.verifyPass.classList.toggle('d-block');
		}).catch(err => {
			this.g_spinner = false;
			console.log({err:err})
			})
		},
        sendOtp(em){
			this.t_email = em;
            axios.post(`/send-otp`, {email: em}).then((response) => {
                this.timer = 60;
                this.intervalT = setInterval(() => {
                    this.timer--;
                }, 1000);
              }).catch((error) => {
                console.log(error)
            });
        },
        ReSendOtp(){
			clearInterval(this.intervalT);
			if(this.t_email === undefined || this.t_email == ''){
				return;
			}
            axios.post(`/send-otp`, {email: this.t_email}).then((response) => {
                this.timer = 60;
                this.intervalT = setInterval(() => {
                    this.timer--;
                }, 1000);
              }).catch((error) => {
                console.log(error)
            });
        },
		vNewAcc(){
			
			this.g_spinner = true;
			let data = {
				otp: document.getElementById('otp1').value+document.getElementById('otp2').value+document.getElementById('otp3').value+document.getElementById('otp4').value+document.getElementById('otp5').value+document.getElementById('otp6').value,
				register: '1',
				// email: this.forget_email,
				name : this.name,
				phone: this.phone_num,
				is_valid_phone: this.phone_validate,
				country_data: this.r_country_data,
				agree: this.agree,
			}
			let url = '/signup-verify';
			if(this.verify_type == 'login'){
				url = '/verify-otp';
				 data = {
					otp: document.getElementById('otp1').value+document.getElementById('otp2').value+document.getElementById('otp3').value+document.getElementById('otp4').value+document.getElementById('otp5').value+document.getElementById('otp6').value,
					email: this.forget_email,
				}

			}
			console.log("this.verify_type", this.verify_type);
			this.$http.post(url,data).then(response => {

				if(response.data.code == 200)
				{
					this.name = '';
					this.username = '';
					this.phone = '';
					this.country_data = '';
					this.email = '';
					this.password = '';
					this.password_confirmation = '';

					this.verify_pass = '';
					if (!this.login_otp_send) {
						window.localStorage.setItem('successModal', '1');
					}
					
					window.localStorage.setItem('authName', response.data.data.user.name)
					window.localStorage.setItem('auth', response.data.data.user.id)
					window.localStorage.setItem('authToken', response.data.data.token)
					// document.getElementById('open-reset-3').click();
					this.$store.state.auth.profile = response.data.data.user;
        			location.replace('/')
				}else{
					this.verify_pass = response.data.message;
				}
				
			this.g_spinner = false;
			// this.$refs.verifyPass.classList.toggle('show');
			// this.$refs.verifyPass.classList.toggle('d-block');
		}).catch(err => {
			this.g_spinner = false;
			console.log({err:err})
			})
		},
		resetPass(){

			this.g_spinner = true;
            const form = document.getElementById('reset-form');
            const formData = new FormData(form);
			this.$http.post('/resetpassword?email='+this.forget_email,formData).then(response => {

				if(response.data.code == 200)
				{
					this.change_pass = '';
					window.localStorage.setItem('successModal','1')
					this.$router.go('/');
					// window.location.replace('/')

				}else{
					this.change_pass = response.data.message;
				}
			this.g_spinner = false;
			// this.$refs.verifyPass.classList.toggle('show');
			// this.$refs.verifyPass.classList.toggle('d-block');
		}).catch(err => {
				this.g_spinner = false;
				console.log({err:err})
			})
		},
		redir(){
			// close-suc
			// window.location.replace('/')
			document.getElementById('close-suc').click();
		},
		redir2(){
			// close-suc
			window.location.replace('/')
		}
	},
	mounted() {
        let lang = window.localStorage.getItem('lang');
		console.log('language', lang);
		if(window.localStorage.getItem('lang') === undefined && window.localStorage.getItem('lang') == null){
			lang = 'ar';
		}
		console.log('language', lang);
		this.bindPropsR.placeholder = this.$t('gen.enter_your_whatsapp_number', lang);
		this.bindPropsL.placeholder = this.$t('gen.enter_your_whatsapp_number', lang);
		this.bindProps.placeholder = this.$t('gen.enter_your_whatsapp_number', lang);

		document.querySelectorAll('.otp-input').forEach(function (input, index, inputs) {
            input.addEventListener('input', function (e) {
                this.value = this.value.replace(/\D/g, '');
                if (this.value.length > this.maxLength) {
                    this.value = this.value.slice(0, this.maxLength);
                }
                if (this.value.length === 1) {
                    this.blur();
                    this.classList.add('valadded');
                    var nextInput = inputs[index + 1];
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
            });

            input.addEventListener('keyup', function (e) {
                if ((e.key === 'Backspace' || e.key === 'Delete') && !this.classList.contains(
                        'valadded') && index > 0 && this
                    .value.length === 0) {
                    this.blur();
                    var prevInput = inputs[index - 1];
                    if (prevInput) {
                        prevInput.focus();
                        prevInput.value = '';
                        prevInput.classList.remove('valadded');
                    }
                }
                if ((e.key === 'Backspace' || e.key === 'Delete') && this.classList.contains(
                        'valadded')) {
                    this.value = '';
                    this.classList.remove('valadded');
                }
            });

            input.addEventListener('focus', function (e) {
                if (index === 0) {
                    return;
                }
                var firstInput = inputs[0];
                if (firstInput.value.length < 1) {
                    firstInput.focus();
                }
                var prevInput = inputs[index - 1];
                if (prevInput && prevInput.value.length < 1) {
                    prevInput.focus();
                }
            });
            input.addEventListener('paste', function (e) {
                e.preventDefault();
                var pastedData = e.clipboardData.getData('text/plain');
                var digits = pastedData.match(/\d/g);
                if (digits) {
                    var currentInputIndex = index;
                    digits.forEach(function (digit) {
                        var currentInput = inputs[currentInputIndex];
                        if (currentInput) {
                            currentInput.value = digit;
                            currentInput.classList.add('valadded');
                            currentInput.blur();
                            var nextInput = inputs[currentInputIndex + 1];
                            if (nextInput) {
                                nextInput.focus();
                            }
                            currentInputIndex++;
                        }
                    });
                }
            });

        });
		if(window.localStorage.getItem('successModal') == '1'){
			document.getElementById('open-success-2').click();
			window.localStorage.setItem('successModal','0');
			setTimeout(() => {
				document.getElementById('close-suc').click();
				
			}, 3000);
		}
		const input = document.querySelector('[autocomplete=one-time-code');
		input.addEventListener('input', () => input.style.setProperty('--_otp-digit', input.selectionStart));
		// let cur_time = 0;
		// const date = new Date();
		// const offset = date.getTimezoneOffset();
		// console.log("timeZone:", offset)
		// const sendDate = (new Date()).getTime();
		// fetch('https://timeapi.io/api/Time/current/zone?timeZone=Africa/Cairo')
    	// .then(response => response.json()).then(data => {
		// 	const receiveDate = (new Date()).getTime();
		// 	const differ = ( receiveDate - sendDate);
		// 		console.log("time response in:",differ)
		// 		cur_time = (new Date(data.dateTime)).getTime() - differ;
		// 		console.log("time response:",cur_time)
		// }).catch(err => {
		// 	console.log({err:err})
		// });

	},
}
</script>
<style>
	.vti__dropdown-list{
		z-index: 99;
	}

	.otp-new-s{
		direction: ltr;
		display:flex;
		justify-content: center;
	}
	.otp-new-s input{
		direction: ltr;
   		text-align: left;
	}
	.otp-new-s :where([autocomplete=one-time-code]) {
  --otp-digits: 6;
  --otp-ls: 2ch;
  --otp-gap: 1.25;

  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);
  --_otp-digit: 0;

  all: unset;
  background: 
  linear-gradient(90deg, 
    var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),
    transparent 0),
    linear-gradient(90deg, 
    var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
    transparent 0
  );
  background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
  background-repeat: no-repeat, repeat-x;
  background-size: var(--_otp-bgsz) 100%;
  caret-color: var(--otp-cc, #222);
  caret-shape: block;
  clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
  font-family: ui-monospace, monospace;
  font-size: var(--otp-fz, 2.5em);
  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
  letter-spacing: var(--otp-ls);
  padding-block: var(--otp-pb, 1ch);
  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
}

/* For this demo */
.otp-new-s label span {
  display: block;
  font-family: ui-sans-serif, system-ui, sans-serif;
  font-weight: 500;
  margin-block-end: 1ch;
}
</style>