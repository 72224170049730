<template>
  <nav class="navbar navbar-expand-xl navbar-light bg-light" :class="(IsAuth)? 'user-logged' : '' ">
		<div class="container">
			<router-link to="/" :class="'navbar-brand'">
				<img v-lazy="$store.state.home.appInfo.logo" style="max-width: 145px !important; max-height: 50px !important;" alt="">
			</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" :class="toggleShow" id="navbarSupportedContent">
				<div class="wr">
					<!-- show in sm screen start -->
					<div class="menu-top d-flex d-xl-none">
						<router-link to="/" :class="'navbar-brand'">
							<img v-lazy="$store.state.home.appInfo.logo" alt="">
						</router-link>
						<button class="close nav-close" id="nav-close-1" @click="ToggleClassShow">
							<i class="fas fa-times"></i>
						</button>
					</div>
					<!-- show in sm screen end -->
					<ul class="navbar-nav">
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'dashboard')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
							<router-link to="/dashboard" class="nav-link">
								{{$t('gen.dashboard')}}
							</router-link>
						</li>
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'wallet')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
							<router-link class="nav-link" to="/wallet">
                                {{$t('gen.wallet')}}
                            </router-link>
						</li>
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'favourite')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
                            <router-link to="/favourite" class="nav-link">
                                {{$t('gen.my_fav')}} 
							</router-link>
						</li>
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'win-auctions')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
							<router-link class="nav-link" to="/win-auctions">
                                {{$t('gen.your_win_auctions')}}
                            </router-link>
						</li>
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'profile')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
                            <router-link to="/profile" class="nav-link">
                                {{$t('nav.edit')}}
							</router-link>
						</li>
						<li  v-if="IsAuth" :class="($store.state.home.active_nav == 'active-auctions')? 'd-block d-sm-none nav-item bg-nav-item' : 'd-block d-sm-none nav-item'">
                            <router-link to="/active-auctions" class="nav-link">
								{{$t('gen.active_auctions')}}  
							</router-link>
						</li>
						<!-- <li :class="($store.state.home.active_nav == 'auctions')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/auctions" :class="'nav-link'">{{ $t('gen.aucs') }}</router-link>
						</li> -->
						<li  :class="($store.state.home.active_nav == 'filter')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/filter" :class="'nav-link'">{{$t('nav.cars')}}</router-link>
						</li>
						<li v-if="$store.state.home.appInfo?.create_auctions_show == '1'" :class="($store.state.home.active_nav == 'create-auctions')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/create-auctions" :class="'nav-link'" v-if="$store.state.home.htmlLang == 'ar'">{{$store.state.home.appInfo?.create_auctions_title_ar }}</router-link>
							<router-link to="/create-auctions" :class="'nav-link'" v-else>{{$store.state.home.appInfo?.create_auctions_title_en }}</router-link>
						</li>
						<li  :class="($store.state.home.active_nav == 'fqe')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/fqe" class="nav-link" > {{$t('nav.fqe')}}</router-link>
						</li>
						<!-- <li class="nav-item bg-nav-item">
							<router-link to="/packages" :class="'nav-link'">اشترك الان</router-link>
						</li> -->
						<!-- <li class="nav-item">
							<a class="nav-link" href="#"> بيع سيارتك</a>
						</li> -->
						<li  :class="($store.state.home.active_nav == 'contact-us')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/contact-us" class="nav-link" > {{$t('nav.contact')}}</router-link>
						</li>
						<!-- <li  :class="($store.state.home.active_nav == 'about')? 'nav-item bg-nav-item' : 'nav-item'">
							<router-link to="/about" class="nav-link" > {{$t('nav.about')}}</router-link>
						</li> -->

						<li  v-if="IsAuth" :class="'d-block d-sm-none nav-item'">
                            <button @click="logout" type="button" class="nav-link" style="color:red;">
                                {{$t('nav.logout')}}
                            </button>
						</li>
					</ul>
					<form class="form-inline" v-on:submit.prevent="onEnterSearch" id="search-sec">
						<div class="icon-inp-wr">
							<span> <feather-icon
								icon="SearchIcon"
								class="cursor-pointer"
								size="20"
								/></span>
							<input v-model="search" class="form-control" @click="showRecList" v-on:keyup.enter.prevent="onEnterSearch" :placeholder="$t('nav.search') " aria-label="Search">
							<div class="autocomplete-s" v-if="show_list_auto">
								<ul>
									<li v-for="(item, index) in autocompleteList" :key="'88' + index">
										<router-link :to="`/car/${item.slug}`">{{ item.title }}</router-link>
									</li>
									<li>
										<a href="#" @click="onEnterSearch">{{$t('nav.searchtxt')}}: {{ search }}</a>
									</li>
								</ul>
							</div>
						</div>
					</form>
					<a href="#" v-if="!IsAuth" class="btn btn-success login-btn" data-toggle="modal" data-target="#loginmo" id="lgn-btn"> {{$t('nav.login')}}</a>
					<a href="#" @click="resetRegData" v-if="!IsAuth" class="btn btn-success login-btn" data-toggle="modal" data-target="#regmodal"> 
						{{$t('nav.signup')}}</a>
				</div>
			</div>
			<div class="nav-abs" v-if="!IsAuth">
				
                <a v-if="$store.state.home.htmlLang == 'ar'  " href="#" @click="changeLocale('en')" class="lang">
                    <span>En</span>
                    <img src="../@core/pic/lang2.png" alt="">
                </a>
                <a v-else href="#" @click="changeLocale('ar')" class="lang">
                    <span>عربي</span>
                    <img src="../@core/pic/lang2.png" alt="">
                </a>
			</div>
			
            <div class="nav-abs" v-if="IsAuth">
                <div class="user-wr">

                    <div class="dropdown dr-style1 ">
                        <button class="btn  dropdown-toggle" type="button" id="dropdown2" data-toggle="dropdown"
                            aria-expanded="false">
                            <img v-lazy="($store.state.profile.profile.image) ? $store.state.profile.profile.image : '../@core/pic/user.png'" alt="">
                            <span class="user-name d-none d-sm-inline-block">
                                {{$store.state.profile.profile.name}}
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown2">
							<router-link to="/dashboard" class="dropdown-item">
								<feather-icon
									icon="UserIcon"
									/>
									{{$t('gen.dashboard')}}

							</router-link>
                            <router-link class="dropdown-item" to="/wallet">
								<feather-icon
									icon="CreditCardIcon"
									/>
                                {{$t('nav.wallet')}}
                            </router-link>
							<router-link to="/favourite" class="d-none d-sm-block dropdown-item">
								<feather-icon icon="StarIcon" />
                                {{$t('gen.my_fav')}}
							</router-link>
                            <router-link class="dropdown-item" to="/win-auctions">
								<feather-icon icon="AwardIcon" />
                                {{$t('gen.your_win_auctions')}}
                            </router-link>
							<router-link to="/active-auctions" class="d-none d-sm-block dropdown-item">
								<feather-icon icon="TrendingUpIcon" />
								{{$t('gen.active_auctions')}}  
							</router-link>
                            <router-link to="/profile" class="dropdown-item">
                                <feather-icon icon="EditIcon" />
                                {{$t('nav.edit')}}
							</router-link>
                            <router-link to="/fqe" class="dropdown-item">
								<feather-icon
									icon="HelpCircleIcon"
									/>
									{{$t('nav.fqe')}}
							</router-link>
                            <button @click="logout" type="button" class="dropdown-item">
								<feather-icon
									icon="LogOutIcon"  class="color-red"
									/>
									{{$t('nav.logout')}}
                            </button>
                        </div>
                    </div>
                </div>
                <a v-if="$store.state.home.htmlLang == 'ar'" href="#" @click="changeLocale('en')" class="lang">
                    <span>En</span>
                    <img src="../@core/pic/lang2.png" alt="">
                </a>
                <a v-else href="#" @click="changeLocale('ar')" class="lang">
                    <span>عربي</span>
                    <img src="../@core/pic/lang2.png" alt="">
                </a>
            </div>
		</div>
	</nav>
</template>

<script>
import store from '@/store'
  import axios from "../axios";
import { mapGetters, mapActions ,mapMutations} from "vuex";
export default {
	data() {
		return {
			toggleShow: '',
			userName: '',
			search: '',
			IsAuth: false,
			show_list_auto: false,
			autocompleteList:[]
		}
	},
	watch: {
		search(val){
			this.atuoComplete();
		}
	},
	mounted() {
		window.addEventListener('click', (e) => {   
			if (document.getElementById('search-sec').contains(e.target)){
				// Clicked in box
			} else{
				// Clicked outside the box
				this.show_list_auto = false;
			}
		});
		let token = window.localStorage.getItem('authToken') || null;
		if (token != null) {
			this.IsAuth=true;
			this.userName = window.localStorage.getItem('authName')
			
			this.$store.dispatch("profile/fetchProfile", this.$route.params.id);
			if(this.$store.state.profile.profile.verify_phone == 0){
                    this.$toast.open({
                        message: this.$t('gen.must_verify_your_phone_to_bid'),
                        type: 'error',
                        // all of other options may go here
                    });
                    
                }
			if(this.$store.state.profile.profile.active == 0){
					window.localStorage.removeItem('authToken')
					location.replace('/')
                }
		}	
		// console.log(this.IsAuth);	
	},
	methods: {
    	...mapActions("auth", ["logout"]),
		ToggleClassShow(){
			if(this.toggleShow == '')
			{
				this.toggleShow = 'hidden'
			}else{
				this.toggleShow = ''
			}


		},
		changeLocale(lang){
			window.localStorage.setItem('lang',lang);
			let h = document.querySelector('html');
			h.setAttribute('lang',lang);
			this.$store.state.home.htmlLang = window.localStorage.getItem('lang');
			this.$i18n.locale = lang;
        	window.location.reload()
		},
		resetRegData(){
			this.$store.state.auth.errors = {};
			this.$store.state.auth.registererrors = {};

		},
		onEnterSearch(){
			this.$router.push({name: 'filter', query:{search: this.search} })
		},
		showRecList(){
			if( this.autocompleteList.length ){
				this.show_list_auto = true;
			}
		},
		atuoComplete(){
			if(this.search.length > 4){
				axios.get(`/search-recommendations`, {keywords: this.search}).then((response) => {
					this.autocompleteList = response.data.data.rows;
					this.showRecList()
				}).catch((error) => {
					console.log(error)
				});

			}
		}
	},
}
</script>
<style >
.icon-inp-wr span {
    position: absolute !important;
}

input::placeholder, .form-control::-moz-placeholder {
    color: #555;
    font-size: .875rem;
    line-height: 2;
    font-family: 'Almarai', sans-serif !important;
    display: inline-block;
}
.form-control::-webkit-input-placeholder {
    color: #555;
    font-size: .875rem;
    line-height: 2;
    font-family: 'Almarai', sans-serif !important;
    display: inline-block;
}

.form-control::-ms-input-placeholder {
    color: #555;
    font-size: .875rem;
    line-height: 2;
    font-family: 'Almarai', sans-serif !important;
    display: inline-block;
}

.form-control::-moz-placeholder {
    color: #555;
    font-size: .875rem;
    line-height: 2;
    font-family: 'Almarai', sans-serif !important;
    display: inline-block;
}

.form-control::-moz-placeholder {
    color: #555;
    font-size: .875rem;
    line-height: 2;
    font-family: 'Almarai', sans-serif !important;
    display: inline-block;
}
.autocomplete-s {
    position: absolute;
    top: 50px;
    background: #fff;
	border:1px solid #d1c5c5;
	border-top: 0px;
    width: 100%;
    padding: 2px 10px;
}
.autocomplete-s ul {
	list-style: none;
	padding-bottom: 5px;
}
.autocomplete-s ul li{
	border-bottom: 1px solid #dfd0d0;
    padding: 5px 0px;
}
.autocomplete-s ul li a{}
.autocomplete-s ul li a:hover {
    color: #00a550 !important;
}
</style>